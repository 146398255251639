<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <div v-if="form.user">
      <b-row v-if="form.user.profile">
        <b-col cols="6">
          <b-list-group >
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex justify-content-between" v-if="form.user.profile">
                <span>
                  <img :src="$backendHost+form.user.profile.photo" width="150" alt="">
                </span>
                <span v-if="form.user.profile.work_book_file">
                  <a :href="$backendHost+form.user.profile.work_book_file" target="_blank" style="font-size: xxx-large">
                    <b-icon icon="file-earmark"></b-icon>
                  </a>
                </span>
                <span v-for="file in form.user.files" :key="file.id">
                  <a :href="$backendHost+file.file" target="_blank" style="font-size: xxx-large">
                    <b-icon icon="file-earmark"></b-icon>
                  </a>
                </span>
              </div>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Username') }}</h5>
              </div>
              <p>
                {{ form.user.first_name }} {{form.user.last_name}}
              </p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Experience') }}</h5>
              </div>
              <p><feather-icon icon="StarIcon" class="mr-75"/>{{ form.user.profile.cur_experience.name }}</p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Contact') }}</h5>
              </div>
              <p class="mb-0"><feather-icon icon="PhoneIcon" class="mr-75"/>{{ form.user.profile.phone_number }}</p>
              <p v-if="form.user.profile.second_number_second" class="mb-0"><feather-icon icon="PhoneIcon" class="mr-75"/>{{ form.user.profile.second_number_second }}</p>
            </b-list-group-item>
            <b-list-group-item>
              <h5>{{$t('message.Jury')}}</h5>
              <hr>
              <div v-for="j in form.jury" :key="j.id" class="w-100 mb-2 border-bottom mb-2">
                <div class="d-flex justify-content-between ">
                  <h6 >{{ j.jury.last_name }} {{ j.jury.first_name }}</h6>
                  <b-button variant="danger" size="sm" @click="removeJury(j.id)"><b-icon icon="trash"></b-icon></b-button>
                </div>
              </div>
              <hr>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="6">
          <b-list-group>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.School') }}</h5>
              </div>
              <p class="mb-0">
                № {{form.user.profile.school_number}}
              </p>
              <p class="mb-0"><feather-icon icon="PhoneIcon" class="mr-75"/> {{ form.user.profile.school_phone_number }}</p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.SchoolType') }}</h5>
              </div>
              <p>
                {{ form.user.profile.user_school_type.name }}
              </p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Region') }}</h5>
              </div>
              <p><feather-icon icon="FlagIcon" class="mr-75"/>{{ form.user.profile.region.nameuz }}
                {{ form.user.profile.rayon.nameuz }} {{form.user.profile.street}}</p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Birthdate') }}</h5>
              </div>
              <p><feather-icon icon="CalendarIcon" class="mr-75"/>{{ form.user.profile.birth_date }}</p>
            </b-list-group-item>
            <b-list-group-item class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>{{ $t('message.Status') }}</h5>
              </div>
              <span v-if="form.display_status">
                <p><span>{{form.display_status.status.name}}</span>
              </p>
              <p>{{form.display_status.description}}</p>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
    <hr>
    <validation-observer ref="observer" class="w-100">
      <b-row v-if="form.status">
        <b-col cols="6" v-if="form.status.status_name === 'accepted'">
          <div class="w-100">
            <b-form-group :label="$t('message.Jury')"><validation-provider
                #default="{ errors }"
                :name="$t('message.Jury')"
                rules="required"
            >
              <b-form-select :options="juries" text-field="full_name" value-field="id" v-model="form.jury_id"></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Variant')"
              rules="required"
          >
            <v-select :options="variants" label="name_ru" class="mt-2" v-model="appVariant"></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <hr>
      <b-row v-if="questions.length">
        <b-col cols="6" v-for="jury in questions" :key="jury.id">
          <h4>{{jury.last_name}} {{jury.first_name}}</h4>
          <div v-for="(question, index) in jury.questions" :key="index">
            <app-timeline class="mt-2">
              <app-timeline-item
                  :title="question.question.question"
                  :subtitle="question.answer"
                  variant="success"
              />
              <div class="mt-2" v-if="question.marks">
                <div v-if="question.marks.length">
                  <div class="w-100" v-for="mark in question.marks" :key="mark.id">
                    <span v-if="mark.mark" class="text-info">{{$t('message.Mark')}}:{{mark.mark}}</span>
                  </div>
                </div>
              </div>
              <hr>
            </app-timeline>
          </div>
        </b-col>
      </b-row>
      <div v-else class="w-100">
        <div v-for="(question, index) in allquestions" :key="index">
          <app-timeline class="mt-2">
            <app-timeline-item
                :title="question.question.question"
                :subtitle="question.answer"
                variant="success"
            />
            <hr>
          </app-timeline>
        </div>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end" v-if="!mark && form.status">
      <b-button v-if="(form.status.status_name === 'accepted' && !form.jury.length) || form.status.status_name === 'rejected'" variant="warning" size="sm" class="ml-1 mr-1" @click="handleAccept(form.id,'new')">
        {{$t('message.New')}}</b-button>
      <b-button v-if="form.status.status_name === 'new'" variant="warning" size="sm" class="ml-1" @click="handleReject(form.id,'rejected')">{{$t('message.Reject')}}</b-button>
      <b-button v-if="form.status.status_name === 'new'" variant="info" size="sm" class="ml-1 mr-2" @click="handleAccept(form.id,'accepted')">{{$t('message.Accept')}}</b-button>
      <b-button variant="success" @click="saveSubmit" :disabled="!form.status" >{{$t('message.Save')}}</b-button>
    </div>
    <b-modal size="lg" hide-footer title="" v-model="RejectModal">
      <div class="w-100">
        <b-form-group :label="$t('message.Description')">
          <b-form-textarea
              id="textarea"
              v-model="reject.cause"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="w-100 mt-2 d-flex justify-content-end">
        <b-button variant="danger" @click="getRejectApplication" class="mr-1">{{$t('message.Save')}}</b-button>
        <b-button variant="dark" @click="RejectModal=false">{{$t('message.Cancel')}}</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { updateApplication, showApplication, getApplicationQuestions, rejectApplication,removeJury } from '@/api/application'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getStages } from '@/api/nomination'
import { getVariants } from '@/api/variants'


function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    user: get('user',{profile:null}),
    jury_id: get('jury_id', null),
    status_id: get('status_id',null),
    status: get('status',null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
    jury: get('jury', null),
    display_status: get('display_status',null),
    files: get('files', [])
  }
}

const actions = {
  add:null,
  update: updateApplication,
  show: showApplication,
}

export default {
  name: 'Form',
  props:{
    activeJuries: {
      default: null,
    },
    id: {
      default: null
    },
    mark: {
      type: Boolean,
      default: false
    }
  },
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
      juries: [],
      statuses: [],
      questions: [],
      RejectModal: false,
      reject: {
        cause:null,
        application_id: null,
        status_name: ''
      },
      allquestions: [],
      stages: [],
      filters:{
        stage_id: this.$route.params.stageId > 1 ? this.$route.params.stageId : null,
      },
      variants: [],
      appVariant: null,
    }
  },
  created() {
    this.getApplication();
    this.fetchVariants();
    this.filters.stage_id = this.$route.params.stageId;
  },
  methods: {
    reformatData() {
      let form = this.prepareFormData({id: this.form.id,status_id: this.form.status_id,
        jury_id: this.form.jury_id, stage_id: this.$route.params.stageId, variant_id: this.appVariant ? this.appVariant.id : null});

      return form;
    },
    getApplication() {
      getApplicationQuestions({ id:this.$props.id,stage_id: this.$route.params.stageId}).then(res => {
        this.juries = res.data.juries.map(item => Object.assign({},{...item,...{full_name:item.first_name ? item.first_name : ''+' '+item.last_name,marks:[]}}));
        // this.juries = this.juries.filter(item => this.activeJuries.some(a => a.id === item.id));
        this.statuses = res.data.statuses;
        this.questions = res.data.data;
        this.allquestions = res.data.allquestions
        console.log(this.allquestions)
        // this.questions.forEach(item => {
        //   item.marks = [];
        //   for(let i = 0;i<item.question.marks_count;i++) {
        //     item.marks.push({mark: null, question_id:item.id});
        //   }
        // });
      })
    },
    setForm(data) {
      this.form = initForm(data);
    },
    handleReject(id, status) {
      this.reject.application_id = id;
      this.reject.status_name = status
      this.RejectModal = true;
    },
    fetchVariants() {
      getVariants({stage_id: this.$route.params.stageId}).then(res => {
        this.variants = res.data.data;
      })
    },
    getRejectApplication() {
      rejectApplication(this.reject).then(() => {
        this.RejectModal = false
        // this.fetchList()
        this.$emit('saved')
      })
    },
    handleAccept(id, status) {
      this.reject.application_id = id;
      this.reject.status_name = status
      this.$swal({
        title: this.$t('message.areYouSure'),
        text: this.$t('message.ChangeStatus'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('message.Change'),
        cancelButtonText: this.$t('message.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          rejectApplication(this.reject).then(() => {
            this.$swal({
              icon: 'success',
              title: '',
              text: this.$t('message.StatusChanged'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
            this.$emit('saved')
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: this.$t('message.Cancelled'),
            text: this.$t('message.yrDataSafe'),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    removeJury(id) {
      this.$swal({
        title: this.$t('message.areYouSure'),
        text: this.$t('message.wontReturn'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('message.YesDelete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          removeJury({ id,stage_id: this.$route.params.stageId ? this.$route.params.stageId : 1 }).then(() => {
            this.$swal({
              icon: 'success',
              title: this.$t('message.Deleted'),
              text: this.$t('message.dataHasDeleted'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
            this.fetchData()
            this.getApplication();
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: this.$t('message.Cancelled'),
            text: this.$t('message.yrDataSafe'),
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
